import { all, takeEvery, put, call } from 'redux-saga/effects'
import profileApi from 'services/profile'
import { actions } from './actions'
import notificationMessage from '../../services/notification'

export function* loadProfile({ payload }) {
  try {
    const response = yield call(profileApi.loadProfile, payload)

    yield put({
      type: actions.LOAD_PROFILE_RESULT,
      payload: response.data,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_PROFILE_RESULT,
      error,
    })
  }
}

export function* activateProfile({ user }) {
  try {
    yield call(profileApi.activateProfile, user)
    notificationMessage('Profile update ', 'You have successfully updated profile')
  } catch (error) {
    notificationMessage('Profile update  Error', `${error.response.data.message}`, true)
  }
}

export function* inactivateProfile({ user }) {
  try {
    yield call(profileApi.inactivateProfile, user)
    notificationMessage('Profile update ', 'You have successfully updated profile')
  } catch (error) {
    notificationMessage('Profile update  Error', `${error.response.data.message}`, true)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PROFILE_REQUEST, loadProfile),
    takeEvery(actions.ACTIVATE_PROFILE_REQUEST, activateProfile),
    takeEvery(actions.INACTIVATE_PROFILE_REQUEST, inactivateProfile),
  ])
}
