import { all, takeEvery, put, call } from 'redux-saga/effects'
import averageApi from 'services/average'
import { actions } from './actions'

export function* loadAveragepoints({ payload }) {
  const { userList, fromDate, toDate } = payload

  try {
    const response = yield call(averageApi.loadAverage, userList, fromDate, toDate)
    yield put({
      type: actions.LOAD_AVERAGE_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_AVERAGE_RESULT,
      error,
    })
  }
}

export function* loadAveragepointsTour({ payload }) {
  const { userList, fromDate, toDate, tour } = payload

  try {
    const response = yield call(averageApi.loadAverageTour, userList, fromDate, toDate, tour)
    yield put({
      type: actions.LOAD_AVERAGE_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_AVERAGE_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_AVERAGE_REQUEST, loadAveragepoints),
    takeEvery(actions.LOAD_AVERAGE_TOUR_REQUEST, loadAveragepointsTour),
  ])
}
