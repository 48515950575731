import { loginApi } from './axiosClient'

export default {
  registration: data => {
    return loginApi.post(`auth/analytics/signup`, data)
  },
  activationCode: data => {
    return loginApi.get(`users/${data}/analytics/generate-new-activation-code`)
  },
  resetPass: data => {
    return loginApi.get(`users/analytics/${data}/send-reset-key`)
  },
}
