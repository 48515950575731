export const actions = {
  LOAD_PROFILE_REQUEST: 'users/LOAD_PROFILE_REQUEST',
  ACTIVATE_PROFILE_REQUEST: 'users/ACTIVATE_PROFILE_REQUEST',
  INACTIVATE_PROFILE_REQUEST: 'users/INACTIVATE_PROFILE_REQUEST',
  LOAD_PROFILE_RESULT: 'users/LOAD_PROFILE_RESULT',
}

export function loadProfile(payload) {
  return {
    type: actions.LOAD_PROFILE_REQUEST,
    payload,
  }
}

export function inactivateProfile(user) {
  return {
    type: actions.INACTIVATE_PROFILE_REQUEST,
    user,
  }
}

export function activateProfile(user) {
  return {
    type: actions.ACTIVATE_PROFILE_REQUEST,
    user,
  }
}
