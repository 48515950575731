export const actions = {
  LOAD_TOURS_REQUEST: 'users/LOAD_TOURS_REQUEST',
  LOAD_TOURS_RESULT: 'users/LOAD_TOURS_RESULT',
}

export function loadTours(payload) {
  return {
    type: actions.LOAD_TOURS_REQUEST,
    payload,
  }
}
