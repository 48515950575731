import React from 'react'
import { Link } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <Link to="/user/login" className={styles.login__text}>
          <img src="resources/images/logo.png" alt="logo" className={styles.logo} />
        </Link>
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
