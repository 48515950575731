import { all } from 'redux-saga/effects'
import user from './user/sagas'
import users from './users/sagas'
import profile from './profile/sagas'
import points from './points/sagas'
import items from './items/sagas'
import analytics from './analytics/sagas'
import tours from './tours/sagas'
import average from './average/sagas'

export default function* rootSaga() {
  yield all([user(), points(), users(), analytics(), tours(), items(), profile(), average()])
}
