import { all, takeEvery, put, call } from 'redux-saga/effects'
import pointsApi from 'services/points'
import { actions } from './actions'

export function* loadTotalpoints({ payload }) {
  const { userList, fromDate, toDate } = payload

  try {
    const response = yield call(pointsApi.loadPoints, userList, fromDate, toDate)
    yield put({
      type: actions.LOAD_TOTALPOINTS_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_TOTALPOINTS_RESULT,
      error,
    })
  }
}

export function* loadTotalpointsTour({ payload }) {
  const { userList, fromDate, toDate, tour } = payload

  try {
    const response = yield call(pointsApi.loadPointsTour, userList, fromDate, toDate, tour)
    yield put({
      type: actions.LOAD_TOTALPOINTS_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_TOTALPOINTS_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TOTALPOINTS_REQUEST, loadTotalpoints),
    takeEvery(actions.LOAD_TOTALPOINTS_TOUR_REQUEST, loadTotalpointsTour),
  ])
}
