import { actions } from './actions'

const initialState = {
  data: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_ANALYTICS_RESULT:
      return { ...state, data: action.payload }
    case actions.DELETE_ANALYTICS_VALUE:
      return { ...state, data: [] }
    default:
      return state
  }
}
