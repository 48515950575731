import jwt from 'jsonwebtoken'
import { CookieStorage } from 'cookie-storage'

const cookieStorage = new CookieStorage()

export const getId = () => {
  const token = cookieStorage.getItem('accessToken')
  return jwt.decode(token)
}

export const createChartData = income => {
  return income.map(({ date, results }) => ({
    name: date,
    ...results.reduce((memo, { levelNumber, points }) => {
      const buf = memo
      buf[`${levelNumber} lvl`] = points
      return buf
    }, {}),
  }))
}
