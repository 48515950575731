export const actions = {
  LOAD_TOTALPOINTS_REQUEST: 'users/LOAD_TOTALPOINTS_REQUEST',
  LOAD_TOTALPOINTS_TOUR_REQUEST: 'users/TOTALPOINTS_TOUR_REQUEST',
  LOAD_TOTALPOINTS_RESULT: 'users/LOAD_TOTALPOINTS_RESULT',
}

export function loadPoints(payload) {
  return {
    type: actions.LOAD_TOTALPOINTS_REQUEST,
    payload,
  }
}

export function loadPointsTour(payload) {
  return {
    type: actions.LOAD_TOTALPOINTS_TOUR_REQUEST,
    payload,
  }
}
