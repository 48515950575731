import { all, takeEvery, put, call } from 'redux-saga/effects'
import itemsApi from 'services/items'
import { actions } from './actions'

export function* loadCriticalitem({ payload }) {
  const { userList, fromDate, toDate } = payload

  try {
    const response = yield call(itemsApi.loadItems, userList, fromDate, toDate)
    yield put({
      type: actions.LOAD_CRITICALITEM_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_CRITICALITEM_RESULT,
      error,
    })
  }
}

export function* loadCriticalitemTour({ payload }) {
  const { userList, fromDate, toDate, tour } = payload

  try {
    const response = yield call(itemsApi.loadItemsTour, userList, fromDate, toDate, tour)
    yield put({
      type: actions.LOAD_CRITICALITEM_RESULT,
      payload: response,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_CRITICALITEM_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CRITICALITEM_REQUEST, loadCriticalitem),
    takeEvery(actions.LOAD_CRITICALITEM_TOUR_REQUEST, loadCriticalitemTour),
  ])
}
