import { loginApi } from './axiosClient'

export default {
  loadTours: user => {
    if (user) {
      return loginApi.get(`tours/users/${user}`, {
        params: {
          page: 1,
          size: 30,
        },
      })
    }
    return loginApi.get(`tours/`, {
      params: {
        page: 1,
        size: 30,
        queryType: 'ASC',
      },
    })
  },
}
