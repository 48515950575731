import { getId } from 'assets/utils'
import { loginApi } from './axiosClient'

export default {
  loadProfile: profile => {
    const userId = getId()

    return loginApi.get(`users/${userId.sub}/permitted-to-me/${profile}`)
  },
  activateProfile: user => {
    const userId = getId()

    return loginApi.post(`/users/${userId.sub}/activate-permit/${user}`)
  },
  inactivateProfile: user => {
    const userId = getId()

    return loginApi.post(`/users/${userId.sub}/inactivate-permit/${user}`)
  },
}
