import axios from 'axios'
import { CookieStorage } from 'cookie-storage'

const cookieStorage = new CookieStorage()

const ApiHostLogin =
  (process.env && process.env.REACT_APP_API_LOGIN) || 'https://dev.drivefocus-api.com/api/v1/'
const ApiAddressHost =
  (process.env && process.env.REACT_APP_API_ADDRESS) ||
  'https://dev.drivefocus-api.com/statistics-service/api/v1/'

export const loginApi = axios.create({
  baseURL: ApiHostLogin,
  headers: {
    accept: 'application/json',
  },
})
export const addressApi = axios.create({
  baseURL: ApiAddressHost,
  headers: {
    accept: 'application/json',
  },
})

const INTERCEPTOR = {
  resolve: config => {
    const storedToken = cookieStorage.getItem('accessToken')
    if (!config.headers.authorization && !!storedToken) {
      config.headers.Authorization = storedToken
    }
    return config
  },
  reject: error => {
    return Promise.reject(error)
  },
}

loginApi.interceptors.request.use(INTERCEPTOR.resolve, INTERCEPTOR.reject)

addressApi.interceptors.request.use(INTERCEPTOR.resolve, INTERCEPTOR.reject)
