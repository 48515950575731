import React from 'react'
import { BackTop, Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TopBar from 'components/LayoutComponents/TopBar'

@withRouter
@connect()
class MainLayout extends React.PureComponent {
  render() {
    const { children } = this.props

    return (
      <Layout>
        <BackTop />
        <Layout>
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="utils__content">{children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    )
  }
}

export default MainLayout
