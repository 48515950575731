import { all, takeEvery, call, put } from 'redux-saga/effects'
import analytcisApi from 'services/analytics'
import { actions } from './actions'

export function* loadAnalytics({ payload }) {
  const { userList, fromDate, toDate } = payload
  try {
    const response = yield call(analytcisApi.loadAnalytics, userList, fromDate, toDate)
    yield put({
      type: actions.LOAD_ANALYTICS_RESULT,
      payload: response.data,
    })
  } catch (error) {
    yield put({
      type: actions.LOAD_ANALYTICS_RESULT,
      error,
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_ANALYTICS_REQUEST, loadAnalytics)])
}
