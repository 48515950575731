export const actions = {
  LOAD_AVERAGE_REQUEST: 'users/LOAD_AVERAGE_REQUEST',
  LOAD_AVERAGE_TOUR_REQUEST: 'users/LOAD_AVERAGE_TOUR_REQUEST',
  LOAD_AVERAGE_RESULT: 'users/LOAD_AVERAGE_RESULT',
}

export function loadAverage(payload) {
  return {
    type: actions.LOAD_AVERAGE_REQUEST,
    payload,
  }
}

export function loadAverageTour(payload) {
  return {
    type: actions.LOAD_AVERAGE_TOUR_REQUEST,
    payload,
  }
}
