export const actions = {
  LOAD_CRITICALITEM_REQUEST: 'users/LOAD_CRITICALITEM_REQUEST',
  LOAD_CRITICALITEM_TOUR_REQUEST: 'users/LOAD_CRITICALITEM_TOUR_REQUEST',
  LOAD_CRITICALITEM_RESULT: 'users/LOAD_CRITICALITEM_RESULT',
}

export function loadItems(payload) {
  return {
    type: actions.LOAD_CRITICALITEM_REQUEST,
    payload,
  }
}

export function loadItemsTour(payload) {
  return {
    type: actions.LOAD_CRITICALITEM_TOUR_REQUEST,
    payload,
  }
}
