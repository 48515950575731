export const actions = {
  LOAD_ANALYTICS_REQUEST: 'users/LOAD_ANALYTICS_REQUEST',
  DELETE_ANALYTICS_VALUE: 'users/DELETE_ANALYTICS_VALUE',
  LOAD_ANALYTICS_RESULT: 'users/LOAD_ANALYTICS_RESULT',
}

export function loadAnalytics(payload) {
  return {
    type: actions.LOAD_ANALYTICS_REQUEST,
    payload,
  }
}

export function deleteAnalytics() {
  return {
    type: actions.DELETE_ANALYTICS_VALUE,
  }
}
