import React from 'react'
import { LocaleProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import english from 'locales/en-US'

@connect(({ settings }) => ({ settings }))
class Localization extends React.Component {
  render() {
    const { children } = this.props
    const currentLocale = english
    return (
      <LocaleProvider locale={currentLocale.antdData}>
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          {children}
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

export default Localization
