import { getId } from 'assets/utils'
import { addressApi } from './axiosClient'

export default {
  loadAnalytics: async (userIds, fromDate, toDate) => {
    const userId = getId()

    const analytics = await addressApi.get(`drive-logs/${userId.sub}/analyst/`, {
      params: {
        userIds: userIds.join(','),
        fromDate,
        toDate,
      },
    })
    return analytics
  },
}
