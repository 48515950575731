import { getId } from 'assets/utils'
import { loginApi } from './axiosClient'

export default {
  loadUsers: (userStatus, userName) => {
    const userId = getId()

    if (userName) {
      return loginApi.get(`users/${userId.sub}/permitted-to-me`, {
        params: {
          userStatus,
          userName,
        },
      })
    }
    return loginApi.get(`users/${userId.sub}/permitted-to-me`, {
      params: {
        userStatus,
      },
    })
  },
}
