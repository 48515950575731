export const actions = {
  LOAD_USERS_REQUEST: 'users/LOAD_USERS_REQUEST',
  LOAD_USERS_RESULT: 'users/LOAD_USERS_RESULT',
}

export function loadUsers(payload) {
  return {
    type: actions.LOAD_USERS_REQUEST,
    payload,
  }
}
