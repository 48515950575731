import { getId } from 'assets/utils'
import { addressApi } from './axiosClient'

export default {
  loadItems: (userList, fromDate, toDate) => {
    const userId = getId()

    return addressApi.get(`critical-item/${userId.sub}`, {
      params: {
        userIds: userList.join(','),
        fromDate,
        toDate,
      },
    })
  },
  loadItemsTour: (userList, fromDate, toDate, tour) => {
    const userId = getId()

    return addressApi.get(`critical-item/${userId.sub}/tours/${tour}`, {
      params: {
        userIds: userList.join(','),
        fromDate,
        toDate,
      },
    })
  },
}
