import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import users from './users/reducers'
import items from './items/reducers'
import tours from './tours/reducers'
import profile from './profile/reducers'
import points from './points/reducers'
import analytics from './analytics/reducers'
import average from './average/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    users,
    points,
    items,
    tours,
    profile,
    analytics,
    average,
  })
