import { getId } from 'assets/utils'
import { addressApi } from './axiosClient'

export default {
  loadPoints: (userList, fromDate, toDate) => {
    const userId = getId()

    return addressApi.get(`total-points/${userId.sub}`, {
      params: {
        userIds: userList.join(','),
        fromDate,
        toDate,
      },
    })
  },
  loadPointsTour: (userList, fromDate, toDate, tour) => {
    const userId = getId()

    return addressApi.get(`total-points/${userId.sub}/tours/${tour}`, {
      params: {
        userIds: userList.join(','),
        fromDate,
        toDate,
      },
    })
  },
}
